// Reset css
@import '~reset-css/sass/reset';

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

// Styles
@import 'styles/components/index';
@import 'styles/pages/index';
@import 'styles/fonts/index';

* {
  box-sizing: border-box;

  p {
    line-height: 1.3;
  }

}

