.scan-page {
  font-family: 'Open Sans', sans-serif;
  height: 100vh;

  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-basis: 15%;

    padding: 20px 80px;

    svg {
      width: 150px;
      height: 100%;
    }

    .logout {
      height: fit-content;

      background-color: transparent;
      border: none;
      outline: none;

      cursor: pointer;

      color: #F3586D;
      font-size: 16px;
      font-family: inherit;
    }
  }

  .content {
    height: 100%;
    flex-basis: 80%;

    display: flex;
    flex-direction: column;
    align-items: center;

    .user-role {
      flex-basis: 40%;

      display: flex;
      align-items: center;

      font-weight: 600;
      font-size: 73px;
      color: #484848;
    }

    .scan-code-container {
      .waiting,
      .success {
        flex-basis: 60%;

        display: flex;
        flex-direction: column;
        align-items: center;


        .text {
          margin-top: 30px;
          font-weight: 600;
          font-size: 53px;

        }
      }

      .waiting {
        .text {
          color: #F06174;
        }
      }

      .success {
        .text {
          color: #54DC2C;
        }
      }
    }
  }
}