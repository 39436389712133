.success-register-ready-user-page {
  font-family: 'Open Sans', sans-serif;
  height: 100vh;

  &-container {
    height: 100%;

    padding: 40px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    .content {
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 500px;

      & > * {
        margin: 20px 0;
      }

      .title {
        color: #484848;
        font-weight: 600;
        font-size: 50px;
      }

      .description {
        color: #54DC2C;
        font-weight: 600;
        font-size: 24px;
        width: 700px;
        text-align: center;

        @media (max-width: 600px) {
          width: 100%;
        }
      }
    }
  }
}