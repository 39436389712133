.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  font-family: 'Open Sans', sans-serif;

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .form {
    display: flex;
    flex-direction: column;

    margin-top: 50px;

    .input-container {
      width: 600px;
    }
  }

  .error {
    margin-top: 30px;
    height: 30px;

    .text {
      color: #E53935;
      font-size: 18px;
      text-align: center;
    }
  }
}



