.time-button {
  border: 2px solid #FFF;
  border-radius: 50px;

  width: 100%;

  background: transparent;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  &.active {
    color: #F06174;
    background: #FFF;
    border: 2px solid transparent;
  }

  &:disabled {
    border: 2px solid rgba(0, 0, 0, 0.3);
    color: rgba(0, 0, 0, 0.3);

    &:hover {
      background: transparent;
    }
  }
}