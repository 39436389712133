.success-register-page {
  font-family: 'Open Sans', sans-serif;

  height: 100vh;

  &-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    padding: 40px;

    height: 100%;

    .content {
      height: 500px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      & > * {
        margin: 30px 0;
      }

      p {
        color: #484848;
        font-weight: 600;
        font-size: 50px;
        text-align: center;
      }
    }
  }
}