.data-input-container {
  font-family: 'Open Sans', sans-serif;

  height: 60px;
  background-color: white;
  border-radius: 50px;

  box-shadow: 0 3px 6px #00000029;
  border: 1px solid #B9B9B9;

  margin: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    display: flex;
    justify-content: center;

    width: 100%;
    height: 100%;

    border: none;
    outline: none;

    text-align: center;
    font-family: inherit;
    font-size: 16px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-webkit-calendar-picker-indicator {
      display: none;
      outline: none;
      cursor: pointer;
    }

    &::-webkit-search-cancel-button {
      display: none;
    }

    #reset-button {
      display: none;
    }
  }

  .data-input-button {
    border: none;
    background-color: transparent;

    width: 50px;
    height: 50px;

    display: flex;
    align-items: center;
    padding: 0;

    cursor: pointer;
    outline: none;

    &.back {
      justify-content: flex-end;
    }

    &.forward {
      justify-content: flex-start;
    }

    &:disabled {
      cursor: default;

      svg {
        fill: lighten(#212121, 50);
      }
    }

    &,
    & svg {
      transition: all 200ms ease-in-out;
    }
  }
}
