.table-container {
  width: 100%;
  font-family: 'Open Sans', sans-serif;

  .table {
    &-header {
      border-bottom: 4px solid #F55369;

      .table-cell {
        font-family: inherit;

        &:first-child {
          border-left-color: transparent;
          border-top-color: transparent;
          border-radius: 30px 0 0 0;
        }

        &:last-child {
          border-right-color: transparent;
          border-top-color: transparent;
          border-radius: 0 30px 0 0;
        }
      }
    }

    &-row {
      .table-cell {
        padding-bottom: 0;
        font-family: inherit;

        &:first-child {
          border-left-color: transparent;
          border-bottom-color: transparent;
        }

        &:last-child {

          border-right-color: transparent;
          border-bottom-color: transparent;
        }
      }

      &:last-child {
        .table-cell {
          padding-bottom: 20px;

          &:first-child {
            border-radius: 0 0 0 30px;
          }

          &:last-child {
            border-radius: 0 0 30px 0;
          }
        }
      }
    }

    &-cell {
      padding: 20px;
      min-width: 20px;
      text-align: left;

      background: white;
      border-left: 4px solid #F55369;
      border-right: 4px solid #F55369;
    }
  }
}