.volunteers-statistics-page {
  font-family: 'Open Sans', sans-serif;

  &-container {
    background: linear-gradient(180deg, #F06174 0%, #F55268 100%);
    min-height: calc(100vh - 150px);

    padding: 2% 8%;

    .header {
      margin-bottom: 30px;
      height: 40px;
    }

    .content {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;

      .time-select {
        width: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr 1fr;

        .data-input-container {
          grid-column-start: 2;

          .data-input-button {
            svg path {
              fill: #F06174;
            }
          }
        }

        .select {
          width: 280px;
          height: 60px;
          margin-left: auto;
          background-image: url('../../assets/icons/ArrowDownIconPink.svg');
        }
      }

      .times {
        width: 100%;
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 10px;
      }

      .not-found {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;

        &-text {
          color: white;
          font-family: inherit;
          font-size: 30px;
        }
      }
    }
  }
}