.register-nav {
  display: flex;
  justify-content: center;

  padding: 0 20px;

  font-family: 'Open Sans', sans-serif;

  @media (max-width: 600px) {
    padding: 0;

    .link {
      width: 100%;
      text-align: center;
      margin: 0 !important;
    }
  }

  .link {
    margin: 0 20px;
    padding-bottom: 6px;

    text-decoration: none;
    color: #000;

    font-family: inherit;
    font-size: 16px;

    &.active {
      border-bottom: 4px solid #F06174;
    }
  }
}