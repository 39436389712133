.volunteers-page {
  font-family: 'Open Sans', sans-serif;

  &-container {
    background: linear-gradient(180deg, #F06174 0%, #F55268 100%);
    min-height: calc(100vh - 150px);

    padding: 2% 8%;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      height: 40px;

      .search {
        display: flex;
        align-items: center;

        background-color: #FFF;
        border-radius: 50px;

        height: 40px;
        width: 350px;

        input {
          width: 100%;
          height: 100%;
          margin-left: 15px;

          border: none;
          outline: none;
          border-radius: 8px;

          font-size: 18px;

          &::placeholder {
            transition: opacity 100ms ease-in-out;
          }

          &:focus::placeholder {
            opacity: 0;
          }
        }

        button {
          width: 40px;
          height: 100%;

          margin-right: 5px;
          padding: 0;

          background-color: transparent;

          border: none;
          outline: none;
          border-radius: 50px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .not-found {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;

      &-text {
        color: white;
        font-family: inherit;
        font-size: 30px;
      }
    }

    .table {
      &-cell {
        .input-container {
          width: auto;
          max-width: 200px;
          height: 40px;

          margin: 0;
          box-shadow: none;

          input {
            text-align: start;
          }
        }
      }
    }
  }
}