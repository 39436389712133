.button {
  width: 600px;
  height: 60px;

  margin: 10px;
  padding: 0;
  border-radius: 50px;

  background-color: #F06174;

  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #FFF;

  border: none;
  outline: none;

  cursor: pointer;

  transition: all 200ms ease-in-out;


  @media (max-width: 600px) {
    width: 100%;
  }

  &:hover {
    background-color: #E45C6E;
  }

  &:active {
    background-color: #CF5464;
  }

  &:disabled {
    filter: grayscale(1);
    cursor: not-allowed;
  }
}