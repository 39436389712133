.statistics-page {
  font-family: 'Open Sans', sans-serif;

  .content {
    background: linear-gradient(180deg, #F06174 0%, #F55268 100%);
    min-height: calc(100vh - 150px);

    padding: 2% 8%;

    .cards {
      display: flex;
      cursor: default;

      .card {
        flex-basis: 25%;
        height: 150px;

        margin-right: 20px;
        padding: 20px;

        background: #FFF;
        box-shadow: 0 3px 6px #00000029;
        border-radius: 20px;

        font-family: inherit;

        &:last-child {
          margin-right: 0;
        }

        &-title {
          font-weight: 600;
          font-size: 20px;
          color: #484848;

          padding-left: 10px;
        }

        &-value {
          height: 100%;

          font-weight: 600;
          font-size: 55px;
          color: #484848;
          text-align: center;

          padding-left: 10px;
          padding-top: 20px;
        }
      }
    }

    .diagram {

      &-header {
        margin: 40px 30px;
        display: flex;
        justify-content: space-between;

        &-text {
          color: white;
          font-family: inherit;
          font-size: 20px;
          cursor: default;
        }

        &-nav {
          .link {
            margin: 0 15px;
            padding: 5px 15px;

            text-decoration: none;
            font-family: inherit;
            font-size: 20px;
            line-height: 1;

            color: #FFF;
            border: 3px solid transparent;

            cursor: pointer;

            &.active {
              border: 3px solid #FFF;
              border-radius: 143px;
              transition: border 200ms ease-in-out;
            }
          }
        }
      }

      &-container {
        background: #FFF;
        box-shadow: 0 3px 6px #00000029;

        border-radius: 20px;
        width: 100%;
        min-height: 200px;

        padding: 30px 25px;
      }
    }
  }
}