.checkbox-container {
  width: fit-content;

  display: flex;
  align-items: center;

  cursor: pointer;

  font-family: 'Open Sans', sans-serif;

  .checkbox {
    position: relative;
    top: .1em;

    input {
      position: absolute;

      width: 100%;
      height: 100%;

      z-index: 1;

      opacity: 0;
      margin: 0;

      cursor: pointer;
    }

    &-label {
      margin-left: 10px;

      font-size: 16px;
      font-family: inherit;
      color: inherit;
    }

    &-checked {
      .checkbox-inner {
        background-color: #F06174;
        border: 1px solid #F06174;

        &:after {
          content: '';

          border: 2px solid #FFF;
          border-top: 0;
          border-left: 0;

          opacity: 1;

          transform: rotate(45deg) scale(1) translate(-50%, -50%);
          transition: all .2s cubic-bezier(.12, .4, .29, 1.46) .1s;
        }
      }
    }

    &-inner {
      position: relative;

      display: block;

      width: 24px;
      height: 24px;

      border: 1px solid #B9B9B9;
      border-radius: 7px;

      transition: all .3s;

      &:after {
        content: '';
        position: absolute;

        top: 46%;
        left: 26%;

        width: 6px;
        height: 11px;

        opacity: 0;

        transform: rotate(45deg) scale(0) translate(-50%, -50%);
        transition: all .1s cubic-bezier(.71, -.46, .88, .6), opacity .1s;
      }
    }
  }
}