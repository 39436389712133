.input-container {
  font-family: 'Open Sans', sans-serif;

  width: 580px;
  height: 60px;

  margin: 10px;

  border-radius: 50px;

  box-shadow: 0 3px 6px #00000029;
  border: 1px solid #B9B9B9;

  @media (max-width: 600px) {
    width: 100%;
  }

  .input {
    width: 100%;
    height: 100%;

    border-radius: 50px;
    padding: 10px 20px;

    border: none;
    outline: none;

    font-size: 16px;
    font-family: inherit;

    color: #707070;

    text-align: center;
    text-overflow: ellipsis;

    &::placeholder {
      transition: opacity 100ms ease-in-out;
    }

    &:focus::placeholder {
      opacity: 0;
    }
  }
}