.register-ready-user {
  font-family: 'Open Sans', sans-serif;

  &-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    height: 100%;

    .content {
      margin-top: 20px;
      width: 600px;

      @media (max-width: 600px) {
        width: 90%;
        display: flex;
        align-items: center;
        flex-direction: column;

        .times,
        .select {
          width: 100% !important;
          text-overflow: ellipsis;
        }

        .times {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .data-input-container {
          width: 100%;
        }

        .button {
          width: inherit;
        }
      }

      .times {
        margin: 30px auto 5px;
      }

      .chose-data,
      .you-can-chose-more {
        color: #707070;
        text-align: center;
        margin: 20px;
      }

      .chose-data {
        font-size: 18px;
        font-weight: 600;
      }

      .you-can-chose-more {
        font-size: 14px;
      }

      .select {
        &.active {
          background-color: #F06174;
          border: 1px solid #F06174;
          color: white;
          background-image: url('../../assets/icons/ArrowDownIconWhite.svg');
        }
      }

      .error {
        height: 30px;
        text-align: center;

        color: #E53935;
        font-size: 20px;
      }

      .selected-dates {
        .title {
          color: #707070;
          text-align: center;
          margin: 20px;
          font-size: 18px;
          font-weight: 600;
        }

        .dates {
          margin: 20px 0;

          .date-container {
            color: #707070;
            font-weight: 600;

            .date {
              font-size: 18px;
              margin: 10px 0;
            }

            .time {
              font-size: 16px;
              margin: 5px 0;
            }

            hr {

              border: none;
              background: #A8A8A8;
              height: 1px;
            }

            &:last-child hr {
              display: none;
            }
          }
        }
      }
    }
  }
}