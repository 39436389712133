.register-page {
  font-family: 'Open Sans', sans-serif;

  &-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    .form {
      margin-top: 20px;

      @media (max-width: 600px) {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .times,
        .select {
          width: 90% !important;
          text-overflow: ellipsis;
        }

        .times {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .data-input-container {
          width: 100%;
        }

        .button {
          width: inherit;
        }

        .personal-data-text.show {
          height: 190px !important;
        }
        .languages {
          width: 100%;
          margin: 0 !important;

          .checkboxes {
            flex-direction: column;
            padding: 0;

            & > * {
              //margin: 10px 0;
              width: 100%;
              height: 45px;
            }
          }

          .title {
            margin: 0 !important;
          }
        }
      }

      & > *,
      .languages > * {
        margin: 20px 0;
      }

      .languages {
        color: #707070;
        margin: 30px 0;

        .title {
          width: 100%;
          text-align: center;
          font-weight: 600;
          margin: inherit;
        }

        .checkboxes {
          display: flex;
          justify-content: space-between;
          padding: 0 20px;
          color: inherit;
        }
      }

      .personal-data {
        display: flex;
        align-items: center;
        padding-left: 20px;

        &-label {
          padding-left: 10px;
          color: #707070;

          &:hover {
            text-decoration: underline;
            cursor: pointer;
            user-select: none;
          }
        }

        &-text {
          max-width: 600px;
          overflow: hidden;

          transition: height 200ms ease-in-out;
          margin-bottom: 0;

          &.show {
            height: 100px;
          }

          &.hide {
            height: 0;
          }
        }
      }

      .error {
        height: 30px;
        text-align: center;

        color: #E53935;
        font-size: 20px;
      }
    }
  }
}