.textfield {
  font-family: 'Open Sans', sans-serif;

  resize: none;

  width: 100%;
  height: 170px;

  box-shadow: 0 3px 6px #00000029;
  border: 1px solid #B9B9B9;
  border-radius: 18px;

  padding: 15px;

  font-size: 16px;

  &:focus {
    outline: none;
  }
}