.select {
  font-family: 'Open Sans', sans-serif;
  width: -webkit-fill-available;
  width: -moz-available;
  height: 50px;

  border-radius: 50px;
  border: 1px solid #A4A4A4;

  margin: 10px;
  padding: 0 24px;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  background-image: url('../../../assets/icons/ArrowDownIcon.svg');
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
  background-size: 26px;
  background-color: white;

  outline: none;

  box-shadow: 0 3px 6px #00000029;

  transition: all .2s ease-in-out;

  font-size: 19px;
  text-align-last: center;

  &:disabled {
    filter: grayscale(1);
    cursor: not-allowed;
  }
}