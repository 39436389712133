.admin-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 150px;

  padding: 20px 80px;
  font-family: 'Open Sans', sans-serif;

  .logo {
    width: 150px;
    height: 100%;
  }

  .nav-container {
    display: flex;
    align-items: center;

    .nav {
      margin-left: 50px;

      .link {
        margin: 0 20px;
        padding-bottom: 5px;

        text-decoration: none;
        color: black;

        font-family: inherit;
        font-size: 16px;

        &.active {
          border-bottom: 4px solid #F06174;
        }
      }
    }
  }

  .logout {
    height: fit-content;

    background-color: transparent;
    border: none;
    outline: none;

    cursor: pointer;

    color: #F3586D;
    font-size: 16px;
    font-family: inherit;
  }
}