.volunteers-nav {
  padding: 0 20px;
  height: 100%;

  .link {
    margin: 0 20px;
    padding-bottom: 5px;

    text-decoration: none;
    color: #FFF;

    font-family: inherit;
    font-size: 16px;

    &.active {
      border-bottom: 4px solid #FFF;
    }
  }
}